import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Wetherby Building Systems Ltd`}</h1>
    <p>{`Wetherby Building Systems Ltd (WBS) are a manufacturer and distributor of external wall insulation systems and decorative external renders.`}</p>
    <p>{`NW Renders are an approved contractor of WBS Ltd completing training and assessments on an ongoing basis using WBS products and systems.`}</p>
    <p>{`All WBS systems are guaranteed to perform to pre-determined standards and are supplied with a standard 10 year materials and labour warranty.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      